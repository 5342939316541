import React from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";

function Support() {
    return (
        <iframe src="https://sonicmedia.tech/manual/" style={{width:"100%",height:"95%",border:"none"}}>

        </iframe>
    );
}

export default Support;
