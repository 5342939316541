import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import Billing from "views/Billing.js";
import Support from "views/Support.js";
import Login from "views/login/index.js";
import Contents from "views/contents/index.js";
import ContentForm from "views/contents/form.js";
import ContentDetails from "views/contents/details.js";
import Schedules from "views/schedules/index.js";
import ScheduleForm from "views/schedules/form.js";
import ScheduleDetails from "views/schedules/details.js";
import Tokens from "views/tokens/index.js";
import TokenForm from "views/tokens/form.js";
import TokenDetails from "views/tokens/details.js";
import Analytics from "./views/Analytics";
import TokenExportForm from "./views/tokens/export";
import Members from "views/members/index.js";
import MemberForm from "views/members/form.js";
import MemberDetails from "views/members/details.js";
import Brands from "views/brands/index.js";
import BrandForm from "views/brands/form.js";
import BrandDetails from "views/brands/details.js";
import Notifications from "views/notifications/index.js";
import NotificationForm from "views/notifications/form.js";
import NotificationDetails from "views/notifications/details.js";


const account = JSON.parse(window.localStorage.getItem('userLoggin'));

const dashboardRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon fa fa-fw fa-bar-chart",
        component: Dashboard,
        layout: "/manage",
        // redirect: true
    },
    {
        path: "/tokens",
        name: (account != null && account.media_provider_type == "BROADCAST") ?
            "Channels" :
            "Tokens",
        icon: "nc-icon nc-paper-2",
        component: Tokens,
        hidden: (account != null && account.organization_type == "MEDIA_PROVIDER") ? false : true,
        layout: "/manage"
    },{
        path: "/mpbrands",
        name: "Brands",
        icon: "nc-icon nc-bullet-list-67",
        component: Brands,
        layout: "/manage",
        // redirect: true
        hidden: (account != null && account.organization_type == "MEDIA_PROVIDER" && (account.role == "ADMIN" || account.role == "RESONIC_ADMIN")) ? false : true,
    },
    {
        path: "/contents",
        name: "Campaigns",
        icon: "nc-icon fa fa-fw fa-folder",
        component: Contents,
        layout: "/manage"
    },
    {
        path: "/schedules",
        name: "Schedule",
        icon: "nc-icon fa fa-calendar",
        component: Schedules,
        layout: "/manage"
    },
    {
        path: "/notifications",
        name: "Notifications",
        icon: "nc-icon fa fa-bell",
        component: Notifications,
        layout: "/manage"
    },
    {
        path: "/members",
        name: "Users",
        icon: "nc-icon fa fa-fw fa-users",
        component: Members,
        layout: "/manage",
        // redirect: true
        hidden: (account != null && (account.role == "ADMIN" || account.role == "RESONIC_ADMIN")) ? false : true,
    },
    {
        path: "/analytics",
        name: "Analytics",
        icon: "nc-icon fa fa-fw fa-line-chart",
        component: Analytics,
        layout: "/manage",
        // redirect: true
    },
    {
        path: "/billing",
        name: "Billing",
        icon: "nc-icon nc-money-coins",
        component: Billing,
        layout: "/manage",
        // redirect: true
        hidden: (account != null && (account.role == "ADMIN" || account.role == "RESONIC_ADMIN")) ? false : true,
    },
    {
        path: "/support",
        name: "Support",
        icon: "nc-icon fa fa-question-circle",
        component: Support,
        layout: "/manage",
        // redirect: true
    },

    {
        path: "/user",
        name: "User Profile",
        icon: "nc-icon nc-circle-09",
        component: UserProfile,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/token/form",
        name: null,
        component: TokenForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/token/details",
        name: null,
        component: TokenDetails,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/token/export",
        name: null,
        component: TokenExportForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/content/form",
        name: null,
        component: ContentForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/content/details",
        name: null,
        component: ContentDetails,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/schedule/form",
        name: null,
        component: ScheduleForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/schedule/details",
        name: null,
        component: ScheduleDetails,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/member/form",
        name: null,
        component: MemberForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/member/details",
        name: null,
        component: MemberDetails,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/mpbrand/form",
        name: null,
        component: BrandForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/mpbrand/details",
        name: null,
        component: BrandDetails,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/notification/form",
        name: null,
        component: NotificationForm,
        layout: "/manage",
        redirect: true
    },
    {
        path: "/notification/details",
        name: null,
        component: NotificationDetails,
        layout: "/manage",
        redirect: true
    },
];

export default dashboardRoutes;
