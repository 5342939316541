import React from 'react';
import DataGridList from "../../../components/CommonList/DataGridList.js";

const Index = () => {
    const columns = [
        { name: 'Name', index: 'name', type: 'text' },
        { name: 'Type', index: 'type', type: 'text' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' }
    ];

    const options = {
        crud: true,
        add: {
            route: "/admin/mediaprovider/form"
        },
        edit: {
            route: "/admin/mediaprovider/form"
        },
        details: {
            route: "/admin/mediaprovider/details"
        },
        delete: {
            route: "mediaprovider/"
        },
        enabledOpt: {
            url_edit: 'mediaprovider/',
            field: 'is_active'
        },
        export: {
            route: ""
        },
    }

    const url = "mediaproviders"

    const filters = [
        // { name: "Date From", index: 'modified_after', type: 'date'},
        // { name: "Date To", index: 'modified_before', type: 'date'},
        // { name: "Search", index: 'search', type: 'text'},
    ];

    // // Restablece los filtros en el almacenamiento local
    // window.localStorage.removeItem('filters');
    // localStorage.removeItem('currentPage');

    return (
        <DataGridList url={url} columns={columns} options={options} filters={filters}/>
    );
};

export default Index;
