import React, {useEffect, useState, SyntheticEvent} from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import {useRef} from 'react';
import {toast} from 'react-toastify';
import DatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from 'moment'


const ContentForm = (props) => {

    const [Name, setName] = useState('');
    const [Reference, setReference] = useState('');
    const [Image, setImage] = useState(null);
    const [Thumbnail, setThumbnail] = useState(null);
    const [Url, setUrl] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const [ExpirationDate, setExpirationDate] = useState(null);
    const [ExpirationDateEnabled, setExpirationDateEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const [PreviewImage, setPreviewImage] = useState();
    const [PreviewThumb, setPreviewThumb] = useState();
    const [ShowRemoveImage, setShowRemoveImage] = useState(false);
    const [ShowRemoveThumb, setShowRemoveThumb] = useState(false);
    const [heightPicture, setHeightPicture] = useState(0);
    const aRef = useRef(null);

    const animatedComponent = makeAnimated();
    const [selectedOptions, setSelectedOptions] = useState();
    const [Categories, setCategories] = useState([]);

    const [Every, setEvery] = useState(1);
    const [IntervalName, setIntervalName] = useState('d');

    const [ReengagementValue, setReengagementValue] = useState(1);
    const [ReengagementInterval, setReengagementInterval] = useState('d');

    const [IntervalNames, setIntervalNames] = useState([]);
    const [Color, setColor] = useState('#c27500');

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "content/" + id,
                    {
                        headers: {"Authorization": `Bearer ${account.token}`}
                    })
                    .then(function (response) {

                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setReference(result.ref);
                            setUrl(result.url);
                            setEnabled(result.is_active);
                            setColor(result.color);

                            var expiration = new Date(moment(result.expiration_date).format('YYYY-MM-DDT00:00:00'));
                            setExpirationDate(result.expiration_date != null ? expiration : null);

                            var intervalname = result.repetition_time[result.repetition_time.length -1];
                            setIntervalName(intervalname);

                            var intervalValue = result.repetition_time.substring(0, result.repetition_time.length - 1)
                            setEvery(intervalValue);

                            setReengagementValue(result.reengagement_time.substring(0, result.reengagement_time.length - 1));
                            setReengagementInterval(result.reengagement_time[result.reengagement_time.length -1]);

                            if (result.image != "" && result.image != undefined && result.image != null && result.image != 'null') {
                                setPreviewImage(configData.BASE_PATH_BACKEND + result.image);
                                setShowRemoveImage(true);
                            }
                            if (result.thumbnail != "" && result.thumbnail != undefined && result.thumbnail != null && result.thumbnail != 'null') {
                                setPreviewThumb(configData.BASE_PATH_BACKEND + result.thumbnail);
                                setShowRemoveThumb(true);
                            }

                            if (result.categories != null && result.categories != undefined) {
                                var data = [];
                                result.categories.map((option) => (
                                    data.push({
                                        value: option.id,
                                        label: option.name
                                    })
                                ));
                                setSelectedOptions(data);
                            }
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        toast.error('An error occurred!', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    });
            } catch (err) {
                toast.error('An error occurred!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        var error = false;
        const formData = new FormData(e.currentTarget);
        if (Image == null) {
            if (id > 0)
                formData.delete('image')
            else {
                document.getElementById('error-image').classList.remove('d-none');
                error = true;
            }
        } else {
            document.getElementById('error-image').classList.add('d-none');
        }


        if (Thumbnail == null) {
            if (id > 0)
                formData.delete('thumbnail')
            else {
                document.getElementById('error-thumbnail').classList.remove('d-none');
                error = true;
            }
        } else {
            document.getElementById('error-thumbnail').classList.add('d-none');
        }

        if (!error) {
            setLoadingData(true);

            if (selectedOptions) {
                selectedOptions.map((option) => (
                    formData.append('categories', option.value)
                ))
            }
            formData.append('is_active', Enabled);
            if (ExpirationDate)
                formData.append('expiration_date', moment(ExpirationDate).format('YYYY-MM-DD'));

            var repetitionTime = Every + IntervalName;
            formData.append('repetition_time', repetitionTime);

            var reengagementTime = ReengagementValue + ReengagementInterval;
            formData.append('reengagement_time', reengagementTime);

            try {
                if (id > 0) {
                    axios.put(configData.API_SERVER + 'content/' + id,
                        formData,
                        {
                            headers: {
                                "Authorization": `Bearer ${account.token}`,
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(function (response) {
                            if (response.status == 200 || response.status == 204) {
                                toast.success('Successful Operation !', {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                return history.push('/manage/contents');
                            }
                        })
                        .catch(function (error) {
                            setLoadingData(false);
                        });
                } else {
                    axios.post(configData.API_SERVER + 'content',
                        formData,
                        {
                            headers: {
                                "Authorization": `Bearer ${account.token}`,
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(function (response) {
                            if (response.status == 200 || response.status == 204) {
                                toast.success('Successful Operation !', {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                return history.push('/manage/contents');
                            }
                        })
                        .catch(function (error) {
                            setLoadingData(false);
                        });
                }
            } catch (err) {
                setLoadingData(false);
            }
        }

    }

    const selectImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setImage(file)
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
            setShowRemoveImage(true);
        }
    }

    const deleteImage = async (e) => {
        setPreviewImage();
        aRef.current.value = null;
        setShowRemoveImage(false);
    }

    const selectThumb = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setThumbnail(file)
            setPreviewThumb(URL.createObjectURL(e.target.files[0]));
            setShowRemoveThumb(true);
        }
    }

    const deleteThumb = async (e) => {
        setPreviewThumb();
        aRef.current.value = null;
        setShowRemoveThumb(false);
    }

    const onChangeExpirationDate = () => {
        setExpirationDateEnabled(!ExpirationDateEnabled)
        setExpirationDate(null);
    }

    const getListSize = () => {
        let item = document.querySelector('.user-picture');
        if (item != null)
            setHeightPicture(item.offsetWidth);
    };

    const getCategories = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'categories?limit=100',
                {
                    headers: {"Authorization": `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        var data = [];
                        result.map((option) => (
                            data.push({
                                value: option.id,
                                label: option.name
                            })
                        ));
                        setCategories(data);
                        setLoadingData(false);

                    }
                    if (Categories.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        getById();
        getCategories();

        var intervalNames = [
            {
                id: 's',
                name: 'Seconds'
            },
            {
                id: 'm',
                name: 'Minutes'
            },
            {
                id: 'h',
                name: 'Hours'
            }, {
                id: 'd',
                name: 'Days'
            }

        ];
        setIntervalNames(intervalNames);

        window.addEventListener("resize", getListSize);
        var selector = document.getElementById('title-section-id');
        if (selector != undefined) {
            if (id > 0)
                selector.textContent = "Edit campaign";
            else
                selector.textContent = "Create campaign";
        }
        getListSize();
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}>
                <div className="preloader-spinner"></div>
            </div>
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" type="text" required placeholder='Name' value={Name}
                                                  onChange={(e) => setName(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control name="url" type="text" placeholder='Url' value={Url}
                                                  onChange={(e) => setUrl(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Reference</Form.Label>
                                    <Form.Control name="ref" type="text" placeholder='Reference' value={Reference}
                                                  onChange={(e) => setReference(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Enabled</Form.Label>
                                    <Form.Check name="Enabled" type="checkbox" checked={Enabled}
                                                onChange={(e) => setEnabled(!Enabled)}/>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Image</Form.Label>
                                    {/*<div className="close-picture-container">*/}
                                    {/*{*/}
                                    {/*(ShowRemoveImage) ? <button type="button" className="close btn btn-sm btn-danger" onClick={deleteImage}><i className="nc-icon nc-simple-remove"></i></button> : ""*/}
                                    {/*}*/}
                                    {/*</div>*/}

                                    <label className="picture user-picture" htmlFor="picture__input-image" tabIndex="0"
                                           style={{height: heightPicture}}>
                                        {
                                            (ShowRemoveImage) ?
                                                <img className="picture__image" src={PreviewImage} alt=""/> :
                                                <span>Select an image</span>
                                        }
                                    </label>

                                    <input type="file" ref={aRef} name="image" id="picture__input-image"
                                           className="picture__input" accept="image/png" onChange={selectImage}/>
                                    <span id="error-image" className="text-danger d-none"><i
                                        className="fas fa-circle fa-xs"></i>Required field</span>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Thumbnail</Form.Label>
                                    {/*<div className="close-picture-container">*/}
                                    {/*{*/}
                                    {/*(ShowRemoveThumb) ? <button type="button" className="close btn btn-sm btn-danger" onClick={deleteThumb}><i className="nc-icon nc-simple-remove"></i></button> : ""*/}
                                    {/*}*/}
                                    {/*</div>*/}

                                    <label className="picture user-picture" htmlFor="picture__input-thumb" tabIndex="0"
                                           style={{height: heightPicture}}>
                                        {
                                            (ShowRemoveThumb) ?
                                                <img className="picture__image" src={PreviewThumb} alt=""/> :
                                                <span>Select a thumbnail</span>
                                        }
                                    </label>

                                    <input type="file" ref={aRef} name="thumbnail" id="picture__input-thumb"
                                           className="picture__input" accept="image/png" onChange={selectThumb}/>
                                    <span id="error-thumbnail" className="text-danger d-none"><i
                                        className="fas fa-circle fa-xs"></i>Required field</span>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Expiration Date</Form.Label>
                                    <div className="container-check-input-same-row">
                                        <Form.Check name="ExpirationDateEnabled" type="checkbox"
                                                    checked={ExpirationDateEnabled} onChange={onChangeExpirationDate}/>
                                        <div
                                            className={(ExpirationDateEnabled) ? "input-group mb-3" : "input-group mb-3 disabled"}>
                                            <div className="input-group-prepend calendar-icon">
                                                <span className="input-group-text"><i
                                                    className="fa fa-fw fa-calendar"></i></span>
                                            </div>
                                            <div className="datapickerContainer">
                                                <DatePicker
                                                    selected={ExpirationDate}
                                                    onChange={(date) => setExpirationDate(date)}
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={new Date()}
                                                    showYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                    yearDropdownItemNumber={10}
                                                    scrollableYearDropdown
                                                    // showMonthDropdown
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Categories</Form.Label>
                                    {/*<Form.Control name="url" type="text" placeholder='Url' value={Url} onChange={(e) => setUrl(e.target.value)} />*/}
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        components={animatedComponent}
                                        onChange={(items) => setSelectedOptions(items)}
                                        options={Categories}
                                        value={selectedOptions}
                                    />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Frequency capping</Form.Label>
                                    <div className="container-check-input-same-row repeticion_time">
                                        <span className="text-muted mr-2 text-nowrap">Once every</span>
                                        <Form.Control name="every" type="number" required min={1} placeholder='Every' value={Every}
                                                      onChange={(e) => setEvery(e.target.value)}/>
                                        <div>
                                            <select className="form-control" name="Content" value={IntervalName}
                                                    onChange={(e) => setIntervalName(e.target.value)}>
                                                {IntervalNames.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Re-engagement frequency</Form.Label>
                                    <div className="container-check-input-same-row repeticion_time">
                                        <span className="text-muted mr-2 text-nowrap">Once every</span>
                                        <Form.Control name="every" type="number" required min={1} placeholder='Every' value={ReengagementValue}
                                                      onChange={(e) => setReengagementValue(e.target.value)}/>
                                        <div>
                                            <select className="form-control" name="Content" value={ReengagementInterval}
                                                    onChange={(e) => setReengagementInterval(e.target.value)}>
                                                {IntervalNames.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control name="color" type="color" placeholder='Color' value={Color}
                                                  onChange={(e) => setColor(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/manage/contents"><i
                                    className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i
                                    className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default ContentForm;
