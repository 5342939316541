import React, { useEffect, useState, SyntheticEvent } from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { format } from 'fecha';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css//
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const ScheduleForm = (props) => {
    const [Tokens, setTokens] = useState([]);
    const [Token, setToken] = useState(0);
    const [Contents, setContents] = useState([]);
    const [Content, setContent] = useState(0);
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [Enabled, setEnabled] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const history = useHistory();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "schedule/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setToken(result.token);
                            setContent(result.content);



                            var start_date = new Date(result.start_date);
                            setStartDate(start_date);

                            var end_date = new Date(result.end_date);
                            setEndDate(end_date);

                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        toast.error('An error occurred !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    });
            } catch (err) {
                toast.error('An error occurred !', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            if (id > 0) {
                axios.put(configData.API_SERVER + 'schedule/' + id ,
                    {
                        token: Token > 0 ? Token : e.target.elements.Token.value,
                        content: Content > 0 ? Content : e.target.elements.Content.value,
                        start_date: StartDate,
                        end_date: EndDate,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/manage/schedules');
                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'schedule' ,
                    {
                        token: Token > 0 ? Token : e.target.elements.Token.value,
                        content: Content > 0 ? Content : e.target.elements.Content.value,
                        start_date: StartDate,
                        end_date: EndDate,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/manage/schedules');
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {

        }
    }

    const getTokens = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'tokens' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setTokens(result);
                        setLoadingData(false);

                    }
                    if (Tokens.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const onChangeStartDate = async (date) => {
        setStartDate(date)
        if(date > EndDate){
            setEndDate(date)
        }
    }
    const getContents = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'contents' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setContents(result);
                        setLoadingData(false);

                    }
                    if (Contents.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        getTokens();
        getContents();
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit Schedule";
            else
                selector.textContent = "Create Schedule";
        }
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Token</Form.Label>
                                    <select className="form-control" name="Token" value={Token} onChange={(e) => setToken(e.target.value)}>
                                        {Tokens.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Content</Form.Label>
                                    <select className="form-control" name="Content" value={Content} onChange={(e) => setContent(e.target.value)}>
                                        {Contents.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Start Date</Form.Label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend calendar-icon">
                                            <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                        </div>
                                        <div className="datapickerContainer">
                                            <DatePicker
                                                selected={StartDate}
                                                onChange={onChangeStartDate}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={10}
                                                scrollableYearDropdown
                                                // showMonthDropdown
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>End Date</Form.Label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend calendar-icon">
                                            <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                        </div>
                                        <div className="datapickerContainer">
                                            <DatePicker
                                                selected={EndDate}
                                                onChange={(date) => setEndDate(date)}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={StartDate}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Enabled</Form.Label>
                                    <Form.Check name="Enabled" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/manage/schedules"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default ScheduleForm;
