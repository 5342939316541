import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";

const Index = () => {
    const columns = [
        { name: 'Name', index: 'name', type: 'text' },
        { name: 'Image', index: 'image', type: 'image' },
        { name: 'Thumbnail', index: 'thumbnail', type: 'image' },
        { name: 'Url', index: 'url', type: 'text' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' },
        { name: 'Last Modified', index: 'modified', type: 'date' },
        { name: 'Reference', index: 'ref', type: 'text' }
    ];

    const options = {
        crud: true,
        formdata: true,
        add: {
            route: "/manage/content/form"
        },
        edit: {
            route: "/manage/content/form"
        },
        details: {
            route: "/manage/content/details"
        },
        delete: {
            route: "content/"
        },
        enabledOpt: {
            url_edit: 'content/',
            field: 'is_active'
        },
        export: {
            route: ""
        },
    }

    const url = "contents"

    const filters = [
        { name: "Date From", index: 'modified_after', type: 'date'},
        { name: "Date To", index: 'modified_before', type: 'date'},
        { name: "Search", index: 'search', type: 'text'},
    ];

    // // Restablece los filtros en el almacenamiento local
    // window.localStorage.removeItem('filters');
    // localStorage.removeItem('currentPage');

    return (
        <DataGridList url={url} columns={columns} options={options} filters={filters}/>
    );
};

export default Index;
