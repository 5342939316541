import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer px-0 px-lg-3">
          <p className="copyright text-center">
              ©2023 SonicMedia owned by Active Sonic Technologies Pty Ltd | All rights reserved
          </p>
      </footer>
    );
  }
}

export default Footer;
