import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import { toast } from 'react-toastify';

const MemberForm = (props) => {
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Role, setRole] = useState('');
    const [Roles, setRoles] = useState([]);
    const [Enabled, setEnabled] = useState(true);
    const [AccountPassword, setAccountPassword] = useState('');
    const [AccountConfirmPassword, setAccountConfirmPassword] = useState('');
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "member/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setFirstName(result.first_name);
                            setLastName(result.last_name);
                            setEmail(result.email);
                            setRole(result.role);
                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        toast.error('An error occurred !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    });
            } catch (err) {
                toast.error('An error occurred !', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            if (id > 0) {
                axios.put(configData.API_SERVER + 'member/' + id ,
                    {
                        first_name: FirstName,
                        last_name: LastName,
                        email: Email,
                        password: AccountPassword,
                        role: Role,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/manage/members');
                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'member' ,
                    {
                        first_name: FirstName,
                        last_name: LastName,
                        email: Email,
                        password: AccountPassword,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/manage/members');
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {

        }
    }

    useEffect(() => {
        getById();
        var roles = [
            {
                id: 'ADMIN',
                name: 'ADMIN'
            },
            {
                id: 'CONTENT_MANAGER',
                name: 'CONTENT_MANAGER'
            },
            {
                id: 'FINANCE',
                name: 'FINANCE'
            },
            {
                id: 'VIEW_ONLY',
                name: 'VIEW_ONLY'
            },
        ];
        if(account != null && account.role == "RESONIC_ADMIN")
            roles.push({
                id: 'RESONIC_ADMIN',
                name: 'RESONIC_ADMIN'
            })

        setRoles(roles);
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit user";
            else
                selector.textContent = "Create user";
        }
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control name="first_name" type="text" required placeholder='First Name' value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control name="last_name" type="text" required placeholder='Last Name' value={LastName} onChange={(e) => setLastName(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control name="email" type="email" required placeholder='Email' value={Email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Role</Form.Label>
                                    <select className="form-control" name="Content" value={Role} onChange={(e) => setRole(e.target.value)}>
                                        {Roles.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="name" type="password" required placeholder='Password' value={AccountPassword} onChange={(e) => setAccountPassword(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control name="name" type="password" required placeholder='Confirm Password' value={AccountConfirmPassword} onChange={(e) => setAccountConfirmPassword(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Enabled</Form.Label>
                                    <Form.Check name="is_active" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/manage/members"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default MemberForm;
