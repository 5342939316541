import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import {RRule} from "rrule";
import {format} from "fecha";

const Details = (props) => {
    const [Title, setTitle] = useState('');
    const [Body, setBody] = useState('');
    const [When, setWhen] = useState('');
    const [RRuler, setRRuler] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "notification/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setTitle(result.title);
                            setBody(result.body);
                            setWhen(format(new Date(result.when), 'DD/MM/YYYY, HH:mm'));

                            var rule = RRule.fromString(result.rrule)
                            setRRuler(rule.toText());

                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }else{
            return history.push('/manage/notifications');
        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined)
            selector.textContent = "Notification details";
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Title: </label>
                            <p className="card-text">{Title}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Body: </label>
                            <p className="card-text">{Body}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>When: </label>
                            <p className="card-text">{When}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>RRule: </label>
                            <p className="card-text">{RRuler}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Is Active: </label>
                            <p className="card-text">{Enabled ? "True" : "False"}</p>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <NavLink className="btn btn-secondary" to="/manage/notifications"><i className="fa fa-chevron-left"></i> Back</NavLink>
                </div>
            </div>
        </>
    );
};

export default Details;
