import React, {useEffect, useState} from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Container,
    Row,
    Col, Form, Table,
} from "react-bootstrap";
import DateRangePicker from "../components/DateRangePicker";
import Select from "react-select";
import axios from "axios";
import configData from "../config";
import makeAnimated from "react-select/animated";
import moment from 'moment';
import $ from "jquery";
import ApexCharts from "apexcharts";

function Billing() {
    const [Token, setToken] = useState();
    const [Contents, setContents] = useState([]);
    const [Content, setContent] = useState();
    const [loadingData, setLoadingData] = useState(false);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const animatedComponent = makeAnimated();

    const getContents = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'contents?limit=100',
                {
                    headers: {"Authorization": `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        var data = [];
                        result.map((option) => (
                            data.push({
                                value: option.id,
                                label: option.name + ((option.ref && option.ref != "") ? " [" + option.ref + "]" : "")
                            })
                        ));
                        setContents(data);
                        setLoadingData(false);

                    }
                    if (Contents.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const resetForm = () => {
        setContent([]);
    }
    const generateInvoice = () => {
        setLoadingData(true);
        const arrayContents = [];

        if (Content) {
            Content.map((option) => (
                arrayContents.push(option.value)
            ))
        }

        try {
            axios.post(configData.API_SERVER + 'invoice_export',
                {
                    start_date: moment($('#reportrange').data('daterangepicker').startDate).format('YYYY-MM-DD'),
                    end_date: moment($('#reportrange').data('daterangepicker').endDate).format('YYYY-MM-DD'),
                    tokens: [],
                    contents: arrayContents
                },
                {
                    headers: {"Authorization": `Bearer ${account.token}`},
                    responseType: 'blob',
                })
                .then(function (response) {
                    if (response.status == 200) {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.setAttribute('download', 'Invoice.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    }
                    setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        getContents();
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}>
                <div className="preloader-spinner"></div>
            </div>
            <link rel="stylesheet" type="text/css"
                  href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css"/>
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="card-stats">
                            <Card.Header>
                                <Card.Title as="h4">Filters</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md="4" className="mb-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Date range</Form.Label>
                                            <DateRangePicker
                                                start={moment().subtract(1, 'month').startOf('month')}
                                                end={moment().subtract(1, 'month').endOf('month')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md="4" className="mb-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Campaigns</Form.Label>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                components={animatedComponent}
                                                onChange={(items) => setContent(items)}
                                                options={Contents}
                                                value={Content}
                                            />
                                        </Form.Group>

                                    </Col>
                                    <Col md="4" className="mb-3 d-flex">
                                        <div className="form-footer align-self-center">
                                            <button type="submit" className="btn btn-primary button-filters"
                                                    onClick={generateInvoice}><i
                                                className="fa fa-fw fa-file-text"></i> Generate Invoice
                                            </button>
                                        </div>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default Billing;
