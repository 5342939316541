import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";

const Index = () => {

  const columns = [
    { name: 'Email', index: 'email', type: 'text' },
    { name: 'First Name', index: 'first_name', type: 'text' },
    { name: 'Last Name', index: 'last_name', type: 'text' },
    { name: 'Role', index: 'role', type: 'text' },
    { name: 'Enabled', index: 'is_active', type: 'input-switch' },
  ];

  const options = {
    crud: true,
    add: {
      route: "/manage/member/form"
    },
    edit: {
      route: "/manage/member/form"
    },
    details: {
      route: "/manage/member/details"
    },
    delete: {
      route: "member/"
    },
    enabledOpt: {
      url_edit: 'member/',
      field: 'is_active'
    },
    export: {
      route: ""
    },
  }

  const url = "members"

  const filters = [
    // { name: "Date From", index: 'modified_after', type: 'date'},
    // { name: "Date To", index: 'modified_before', type: 'date'},
    // { name: "Search", index: 'search', type: 'text'},
  ];

  // // Restablece los filtros en el almacenamiento local
  // window.localStorage.removeItem('filters');
  // localStorage.removeItem('currentPage');

  return (
      <DataGridList url={url} columns={columns} options={options} filters={filters}/>
  );
};

export default Index;
