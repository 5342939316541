import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";

const Index = () => {

  const columns = [
    { name: 'Title', index: 'title', type: 'text' },
    { name: 'Body', index: 'body', type: 'text' },
    { name: 'When', index: 'when', type: 'datetime' },
    { name: 'Recurrence', index: 'rrule', type: 'rule' },
    { name: 'Enabled', index: 'is_active', type: 'input-switch' },
  ];

  const options = {
    crud: true,
    add: {
      route: "/manage/notification/form"
    },
    edit: {
      route: "/manage/notification/form"
    },
    details: {
      route: "/manage/notification/details"
    },
    delete: {
      route: "notification/"
    },
    enabledOpt: {
      url_edit: 'notification/',
      field: 'is_active'
    },
    export: {
      route: ""
    },
  }

  const url = "notifications"

  const filters = [
    // { name: "Date From", index: 'modified_after', type: 'date'},
    // { name: "Date To", index: 'modified_before', type: 'date'},
    // { name: "Search", index: 'search', type: 'text'},
  ];

  // // Restablece los filtros en el almacenamiento local
  // window.localStorage.removeItem('filters');
  // localStorage.removeItem('currentPage');

  return (
      <DataGridList url={url} columns={columns} options={options} filters={filters}/>
  );
};

export default Index;
