import React, { useEffect, useState, Component, useRef, Fragment, forwardRef } from 'react'
import { format } from 'fecha';
import {
    Form,
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Accordion,
    Collapse,
    Dropdown
} from "react-bootstrap";
import { NavLink, useHistory,useLocation  } from 'react-router-dom';
import axios from 'axios';
import configData from '../../config';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Icon from 'awesome-react-icons';
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { RRule} from 'rrule'
import queryString from 'query-string';

function DataGridList ({ columns = [], url = "", params = [], filters = [], options = {}, itemsPerPage = 10 }) {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [paramsString, setParamsString] = useState("");
    const [filtersApplied, setfiltersApplied] = useState([]);
    const [filtersAppliedTitle, setfiltersAppliedTitle] = useState("");
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));

    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, settotalpages] = useState(0);
    const [pageSize, pageSizeSet] = useState(itemsPerPage);
    const [ItemPaginate, setItemPaginate] = useState(5);

    const [TokenSelect, setTokenSelect] = useState("Token");
    const [ContentSelect, setContentSelect] = useState("Content");

    const [enableButtons, setEnableButtons] = useState(false);
    const [isVisible, initHs] = useState(false)
    const invokeCollapse = () => {
        return initHs(!isVisible)
    }

    const history = useHistory();
    const location = useLocation();

    const appliedFilters = params;

    const OnDelete = async (id) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                DeleteItem(id);
            }
        })
    }

    const DeleteItem = async (id) => {
        setLoadingData(true);
        const requestOptions = {
            token: `${account.token}`,
            headers: { Authorization: `${account.token}` }
        };
        try {
            axios
                .delete(configData.API_SERVER + options['delete']['route'] + id, { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200 || response.status == 204) {
                        toast.success('Successful Operation !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        getData();

                    } else {
                        toast.error('An error occurred !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    }
                })
                .catch(function (error) {
                    toast.error('An error occurred !', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoadingData(false);

                });
        } catch (err) {
            toast.error('An error occurred !', {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoadingData(false);
        }
    }

    function handlePageClick({ selected: selectedPage }) {
        setLoadingData(true);
        let page = selectedPage + 1;
        setCurrentPage(page);
        localStorage.setItem('currentPage', JSON.stringify(page));
        getData(pageSize, page);
    }
    const OnChangeActiveValue = async (id, value, formdata = false) => {
        try {
            axios.put(configData.API_SERVER + options['enabledOpt']['url_edit'] + id ,
                {
                    is_active: value
                },
                {
                    headers: {
                        "Authorization" : `Bearer ${account.token}`,
                        "Content-Type": formdata ? "multipart/form-data" : "application/json"
                    }
                })
                .then(function (response) {
                    if (response.status == 200 || 204) {
                        toast.success('Successful Operation !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        getData(pageSize, currentPage, false);
                    }
                })
                .catch(function (error) {
                    getData();
                });
        } catch (err) {
            getData();
        }
    }

    const showPerPage = (e) => {
        pageSizeSet(e.target.value)
        setCurrentPage(1);
        localStorage.setItem('currentPage', JSON.stringify(1));
        getData(e.target.value, 1, filters);
    }

    const formRef = useRef(null);

    const resertForm = () => {
        initHs(false);
        formRef.current.reset();
        for (let i = 0; i < formRef.current.length; i++) {
            if (formRef.current[i].type == 'checkbox')
                formRef.current[i].checked = false;

            if (formRef.current[i].type != 'checkbox')
                formRef.current[i].value = ""
        }
        setParamsString("");
        setCurrentPage(1);
        setEnableButtons(false);

        // Restablece los filtros en el almacenamiento local
        window.localStorage.removeItem('filters');
        localStorage.removeItem('currentPage');

        getData(pageSize, currentPage, true, "");
    }

    const activateButtons = (event) => {
        setEnableButtons(false);
        for (let i = 0; i < formRef.current.length; i++) {
            if ((formRef.current[i].type == 'checkbox' && formRef.current[i].checked) || (formRef.current[i].type != 'checkbox' && formRef.current[i].value != "")) {
                setEnableButtons(true);
            }
        }
    }


    const onSearch = (event) => {
        initHs(false);
        event.preventDefault();
        setParamsString("");
        var filterString = "";

        filters.map((item) => {
            let index = item.index
            if (event.target[index] != undefined) {
                item.value = (item.type == "checkbox") ? ((event.target[index].checked) ? 1 : 0) : event.target[index].value;

                if (item.value != undefined && item.value != "" && item.value != null) {
                    filterString += "&" + item.index + "=" + item.value;
                }
            }
        });
        setParamsString(filterString);
        setCurrentPage(1);
        // Guarda los filtros en el almacenamiento local
        window.localStorage.setItem('filters', JSON.stringify(filterString));
        localStorage.setItem('currentPage', JSON.stringify(1));
        getData(pageSize, 1, true, filterString);
    }

    const getData = (pageSizeParams = pageSize, pageNumber = currentPage, showLoading = true, params = paramsString) => {
        if(showLoading)
            setLoadingData(true);

        let start = (pageNumber - 1) * pageSizeParams;
        let limit = pageSizeParams;

        try {
            // const requestOptions = {
            //     token: `${account.token}`,
            //     headers: { Authorization: `${account.token}` }
            // };
            axios.get(configData.API_SERVER + url + "?limit=" + limit + "&offset=" + start + params ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {

                        var result = response.data.results;
                        if (result) {
                            setData(result);                            //
                            setTotalRecords(response.data.count);
                            // setcurrentRecords(result.length);
                            settotalpages(Math.ceil(response.data.count / pageSizeParams));
                        }
                        // window.localStorage.setItem('userLoggin', JSON.stringify(user));
                        // window.location.reload()
                        // setData(result);
                        // setTotalRecords(result.length > 0 ? result[0].total : 0);
                        // settotalpages(Math.ceil(totalRecords / pageSize));
                        setLoadingData(false);
                    }
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    // const getListSize = () => {
    //     let mapaContainer = document.querySelector('.map-container-row');
    //     if(mapaContainer != null)
    //         setHeightMapa(mapaContainer.offsetWidth);
    // };

    const handleEditClick = (currentPage) => {
        // Guardar el número de página actual en el almacenamiento local
        localStorage.setItem('currentPage', JSON.stringify(currentPage));
    };

    useEffect(() => {
        // Recupera los filtros del almacenamiento local
        const savedFilters = JSON.parse(window.localStorage.getItem('filters'));
        if (savedFilters) {
            // Aplica los filtros recuperados
            setParamsString(savedFilters);
        }

        const page = JSON.parse(localStorage.getItem('currentPage'));
        if(page){
            setCurrentPage(page);
            localStorage.removeItem('currentPage');
        }

        getData(pageSize, page ? page : currentPage, true, savedFilters ? savedFilters : "");
    }, []);

    // useEffect(() => {
    //     console.log(currentPage);
    // }, [currentPage]);

    return (
        <div>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <div className="tbar">
                {(options['add']['route'] != "" && options['add']['route'] != undefined && options['add']['route'] != null) ?
                    <div className="add-button-container"><NavLink className="btn btn-primary btn-add"
                             to={{
                                 pathname: options['add']['route']
                             }}
                    ><i className="fa fa-fw fa-plus"></i> Add</NavLink></div>
                    : ""}

                {filters.length > 0 ?
                    <Card className="filterContainer">
                        <a href="#" variant="success" onClick={invokeCollapse}>
                            <span>Filters</span> {isVisible ? <i className="nc-icon fa fa-fw fa-angle-up"></i> : <i className="nc-icon fa fa-fw fa-angle-down"></i>}
                        </a>
                        <Collapse in={isVisible}>
                            <div className="collapsePanel">
                                <Form onSubmit={onSearch} ref={formRef}>
                                    <div className="general-filters-container">
                                        {
                                            filters.map((column, i) => {
                                                let type = column.type
                                                let index = column.index
                                                let name = column.name
                                                switch (type) {
                                                    case "checkbox":
                                                        return (
                                                            <Form.Group className="mb-3 filter-list" key={i}>
                                                                <Form.Label>{name}</Form.Label>
                                                                <Form.Check type="checkbox" name={index} onChange={activateButtons} />
                                                            </Form.Group>
                                                        );
                                                    case "text":
                                                        return (
                                                            <Form.Group className="mb-3 filter-list" key={i}>
                                                                <Form.Label>{name}</Form.Label>
                                                                <Form.Control type="text" placeholder={name} name={index} onChange={activateButtons} />
                                                            </Form.Group>
                                                        );
                                                    case "number":
                                                        return (
                                                            <Form.Group className="mb-3 filter-list" key={i}>
                                                                <Form.Label>{name}</Form.Label>
                                                                <div className="filter-number">
                                                                    {(column.opt != undefined && column.opt != null && column.opt != "") ?
                                                                        <div className="number-opt">
                                                                            <Form.Check
                                                                                inline
                                                                                label="<"
                                                                                defaultChecked
                                                                                value="<"
                                                                                name={column.opt}
                                                                                type="radio"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label=">"
                                                                                value=">"
                                                                                name={column.opt}
                                                                                type="radio"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="="
                                                                                value="="
                                                                                name={column.opt}
                                                                                type="radio"
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                    <Form.Control type="number" placeholder={name} name={index} onChange={activateButtons} />

                                                                </div>
                                                            </Form.Group>
                                                        );
                                                    case "dropdown":
                                                        return (
                                                            <Form.Group className="mb-3 filter-list" key={i}>
                                                                <Form.Label>{name}</Form.Label>
                                                                <Form.Select name={index} onChange={activateButtons}>
                                                                    <option value="">Select option</option>
                                                                    {column.list.map(item =>
                                                                        <option className="p-10" value={item.id}>{item.name}</option>
                                                                    )}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        );
                                                    case "date":
                                                        return (
                                                            <Form.Group className="mb-3 filter-list" key={i}>
                                                                <Form.Label>{name}</Form.Label>
                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend calendar-icon">
                                                                        <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                                    </div>
                                                                    <div className="datapickerContainer">
                                                                        <Form.Control type="date" placeholder={name} name={index} onChange={activateButtons} />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })
                                        }
                                    </div>
                                    <br />
                                    {enableButtons ?
                                        <div className="form-footer">
                                            <button type="submit" className="btn btn-primary button-filters"><i className="fa fa-fw fa-search"></i> Search</button>
                                            <button type="button" className="btn btn-secondary button-filters" onClick={resertForm}><i className="fa fa-fw fa-refresh"></i> Reset</button>
                                        </div>
                                        :
                                        <div className="form-footer">
                                            <button type="submit" className="disabled btn btn-primary button-filters"><i className="fa fa-fw fa-search"></i> Search</button>
                                            <button type="button" className="disabled btn btn-secondary button-filters" onClick={resertForm}><i className="fa fa-fw fa-refresh"></i> Reset</button>
                                        </div>
                                    }
                                </Form>
                            </div>
                        </Collapse>
                    </Card>
                :
                    null
                }
            </div>
            <Card className="card-plain table-plain-bg">
                {/*<div className="tbar">*/}
                    {/*{(options['add']['route'] != "" && options['add']['route'] != undefined && options['add']['route'] != null) ?*/}
                        {/*<NavLink className="btn btn-primary btn-add"*/}
                                 {/*to={{*/}
                                     {/*pathname: options['add']['route']*/}
                                 {/*}}*/}
                        {/*><i className="fa fa-fw fa-plus"></i> Add</NavLink>*/}
                        {/*: ""}*/}
                {/*</div>*/}
                <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover">
                        <thead>
                        <tr>
                            {columns.map((column, i) => (
                                <th key={i} className="datagridHeader">
                                    {column.name}
                                </th>
                            ))}
                            {
                                (options['crud'] == true)
                                    ?
                                    <th className="datagridHeader options">Actions</th>
                                    :
                                    null
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((record, j) => (
                                <tr className={"tr#"+j} key={j}>
                                    {columns.map((col, i) => {
                                        let type = col.type;
                                        var value = record[col.index];
                                        switch (type) {
                                            case "image":
                                                var img = configData.BASE_PATH_BACKEND + value;
                                                return (
                                                    <td key={i} className={record['disabledClass'] + " v-align-middle"}><img className="list-img" src={img} alt="user" height={50} width={50} /></td>
                                                );
                                                break;
                                            case "date":
                                                if (value != "" && value != undefined && value != null && value != 'null')
                                                    if (!isNaN(Date.parse(value))) {
                                                        var date = new Date(value);
                                                        date.setDate(date.getDate());
                                                        value = format(date, 'DD/MM/YYYY');
                                                        return (
                                                            <td key={i}>{value}</td>
                                                        );
                                                    }
                                                break;
                                            case "datetime":
                                                if (value != "" && value != undefined && value != null && value != 'null')
                                                    if (!isNaN(Date.parse(value))) {
                                                        var date = new Date(value);
                                                        value = format(date, 'DD/MM/YYYY, HH:mm');
                                                        return (
                                                            <td key={i}>{value}</td>
                                                        );
                                                    }
                                                break;
                                            case "timestamp":
                                                if (value != "" && value != undefined && value != null && value != 'null') {
                                                    var date = new Date(value);
                                                    date.setDate(date.getDate());
                                                    value = format(date, 'DD/MM/YYYY');
                                                    return (
                                                        <td key={i}>{value}</td>
                                                    );
                                                    break;
                                                }
                                            case "bool":
                                                if (value)
                                                    value = 'True';
                                                else
                                                    value = 'False';
                                                break;
                                            case "input-switch":
                                                if(options['enabledOpt']['url_edit'] != "" && options['enabledOpt']['url_edit'] != undefined && options['enabledOpt']['url_edit'] != null){
                                                    if(options.hasOwnProperty('formdata'))
                                                        return (<td key={i}><BootstrapSwitchButton size="sm" checked={value} onChange={(e) => OnChangeActiveValue(record.id, e, true)} /></td>);
                                                    else
                                                        return (<td key={i}><BootstrapSwitchButton size="sm" checked={value} onChange={(e) => OnChangeActiveValue(record.id, e)} /></td>);
                                                }
                                                else{
                                                    if (value)
                                                        value = 'True';
                                                    else
                                                        value = 'False';
                                                }
                                                break;
                                            case "list":
                                                if (value != "" && value != undefined && value != null && value != 'null') {
                                                    value =  value.map(item => item.name).join(', ');
                                                    return (
                                                        <td key={i}>{value}</td>
                                                    );
                                                    break;
                                                }
                                            case "rule":
                                                if (value != "" && value != undefined && value != null && value != 'null') {
                                                    var rule = RRule.fromString(value)
                                                    value =  rule.toText();
                                                    return (
                                                        <td key={i}>{value}</td>
                                                    );
                                                    break;
                                                }
                                            default:
                                                break;
                                        }

                                        return (
                                            <td key={i}>{value}</td>
                                        );
                                    })}
                                    {
                                        (options['crud'] == true)
                                            ?
                                            <td>
                                                <div className="options-data" key={j}>
                                                    {
                                                        (options['details']['route'] != "" && options['details']['route'] != undefined && options['details']['route'] != null) ?
                                                            <NavLink className={(options['enabledOpt']['field'] == null || record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-detail" : "crud-icons crud-icon-detail disabled"}
                                                                     to={{
                                                                         pathname: options['details']['route'],
                                                                         aboutProps: {
                                                                             id: record.id
                                                                         }
                                                                     }}
                                                            ><Icon name="eye" /></NavLink>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        (options['export']['route'] != "" && options['export']['route'] != undefined && options['export']['route'] != null) ?
                                                            <NavLink className={(options['enabledOpt']['field'] == null || record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-detail" : "crud-icons crud-icon-detail disabled"}
                                                                     to={{
                                                                         pathname: options['export']['route'],
                                                                         aboutProps: {
                                                                             id: record.id,
                                                                             name: record.name
                                                                         }
                                                                     }}
                                                            ><FaCloudDownloadAlt /></NavLink>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        (options['edit']['route'] != "" && options['edit']['route'] != undefined && options['edit']['route'] != null) ?
                                                            <NavLink className={(options['enabledOpt']['field'] == null || record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-edit" : "crud-icons crud-icon-edit disabled"}
                                                                     to={{
                                                                         pathname: options['edit']['route'],
                                                                         aboutProps: {
                                                                             id: record.id
                                                                         }
                                                                     }}
                                                                     onClick={() => handleEditClick(currentPage)}
                                                            ><Icon name="edit-pencil-simple" /></NavLink>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        (options['delete']['route'] != "" && options['delete']['route'] != undefined && options['delete']['route'] != null) ?
                                                            <a href="#" className={(options['enabledOpt']['field'] == null || record[options['enabledOpt']['field']]) ? "crud-icons crud-icon-delete" : "crud-icons crud-icon-delete disabled"} onClick={() => OnDelete(record.id)}>

                                                                <Icon name="trash" />
                                                            </a>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </td>
                                            :
                                            null
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagination mt-25 row">
                        <div className="col-md-4 col-sm-12 mb-25 paginate-flex-mobile">
                            <div className="dataTables-info">
                                <div className="dataTables-info-items-showing">Showing {(totalRecords > 0) ? ((currentPage - 1) * pageSize) + 1 : 0} to {(totalRecords < pageSize) ? totalRecords : currentPage * pageSize} of {totalRecords} entries</div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 mb-25 paginate-flex-mobile">
                            <div className="dataTables-showing-per-page">
                                <span className="mr-5">Show:</span>
                                <select className="mr-5 form-control" defaultValue="10" onChange={showPerPage}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <span>Entries</span>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 mb-25 paginate-flex-mobile">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={(totalRecords < pageSize) ? 1 : totalPages}
                                onPageChange={handlePageClick}
                                forcePage={currentPage - 1}
                                marginPagesDisplayed={ItemPaginate}
                                pageRangeDisplayed={0}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                disabledClassName={"page-link-disabled"}
                                activeClassName={"page-link-active"}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default DataGridList;